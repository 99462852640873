import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getTab, setTab } from '../store/slices/tabSlice'
import {
  selectSelectedQueryArgs,
  setSelectedQueryArgs,
} from '../store/slices/tableSlice'
import { RootState, store } from '../store/store'

export const useTablePageTabs = (
  pathName: string,
  table: string,
  tabValues: string[],
) => {
  const navigate = useNavigate()
  const { id: selectedTab } = useParams()
  const tab = useSelector((state: RootState) => getTab(state, pathName))
  const newTab = tabValues.includes(selectedTab ?? '')
    ? selectedTab ?? ''
    : tab ?? tabValues[0]
  const tableTab = useSelector((state: RootState) =>
    selectSelectedQueryArgs(state, table),
  )

  useEffect(() => {
    if (selectedTab === undefined) navigate(`/${pathName}/${tab}`)
    if (tab !== newTab) store.dispatch(setTab({ tab: pathName, value: newTab }))
    if (tab !== tableTab)
      store.dispatch(setSelectedQueryArgs({ name: table, queryArgs: tab }))
  }, [selectedTab, tab, newTab]) // eslint-disable-line

  return {
    tab: tab !== newTab ? newTab : tab,
    changeTab: (newValue: string | boolean) => {
      navigate(`/${pathName}/${newValue}`)
    },
  }
}

export const usePageTabs = (pathName: string, tabValues: string[]) => {
  const navigate = useNavigate()
  const { id: selectedTab } = useParams()
  const tab = useSelector((state: RootState) => getTab(state, pathName))
  const newTab = tabValues.includes(selectedTab ?? '')
    ? selectedTab ?? ''
    : tab ?? tabValues[0]

  useEffect(() => {
    if (selectedTab === undefined) navigate(`/${pathName}/${tab}`)
    if (tab !== newTab) store.dispatch(setTab({ tab: pathName, value: newTab }))
  }, [selectedTab, tab, newTab]) // eslint-disable-line

  return {
    tab: tab !== newTab ? newTab : tab,
    changeTab: (newValue: string | boolean) => {
      navigate(`/${pathName}/${newValue}`)
    },
  }
}
