import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { createSelector } from '@reduxjs/toolkit'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { ensureError, localizeError } from '../../helpers'
import { useOrg } from '../../hooks/org'
import {
  clientApi,
  REFETCH_TIMEOUT,
  useAddRoleApiV1UsersUserIdRolesRolePatchMutation,
  useDeleteUserApiV1UsersUserIdDeleteMutation,
  useGetBfpUserApiV1UsersUserIdBlockBfpGetQuery,
  useGetRolesApiV1UsersRolesGetQuery,
  useGetUserRolesApiV1UsersUserIdRolesGetQuery,
  useRemoveBfpUserApiV1UsersUserIdBlockBfpDeleteMutation,
  useRemoveRoleApiV1UsersUserIdRolesRoleDeleteMutation,
  UserReadDto,
  UsersPaginatedListRead,
  useSearchUserApiV1UsersUserIdGetQuery,
  useSearchUsersApiV1UsersSearchGetQuery,
} from '../../store/clientApi'
import {
  invalidate,
  selectQueryArgs,
  selectQueryIsSearching,
  selectQuerySearchValue,
  selectRtkData,
  setPerPage,
  setRtkArgs,
  setSearchValue,
  setSortField,
  setSortOrder,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { Button } from '../Button/Button'
import { DialogBox } from '../DialogBox/DialogBox'
import { MoreMenuSvg } from '../Icon/Icon'
import { Menu } from '../Menu/Menu'
import { ReduxPerPage } from '../PerPageSelector/PerPageSelector'
import { Profile } from '../Profile/Profile'
import { SearchField } from '../SearchField/SearchField'
import { Column, DynamicTable } from '../Table/Table'
import { UserCreate } from '../UserCreate'

const invalidateTable = () => store.dispatch(invalidate(USER_TABLE_STATE_NAME))

const NoUsers = () => {
  const { t } = useTranslation('users')
  const { allowUserManagementUi } = useFlags()
  const [addUsersOpen, setAddUsersOpen] = useState(false)

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'center',
        alignContent: 'center',
        flexGrow: 1,
      }}
    >
      <Box component="div" sx={{ flexGrow: 1 }}></Box>
      <img
        alt={t('alt.addUsers')}
        style={{ maxWidth: '240px' }}
        src="/images/add-users.svg"
      />
      <Typography textAlign={'center'} margin={'1em 0'} marginTop={'2.5em'}>
        {t('message.userPageInfo')}
      </Typography>
      <Typography textAlign={'center'}>{t('message.howToAddUsers')}</Typography>

      <Button
        color="primary"
        onClick={() => setAddUsersOpen(true)}
        disabled={!allowUserManagementUi}
        sx={{
          mt: '1.5em',
        }}
      >
        {t('button.add')}
      </Button>
      <Box component="div" sx={{ flexGrow: 1 }}></Box>
      <DialogBox
        title={t('users:title.invite')}
        args={undefined}
        message={
          <UserCreate
            onCreate={() => {
              setAddUsersOpen(false)
            }}
          />
        }
        open={addUsersOpen}
        setOpen={setAddUsersOpen}
        onClose={() => {
          return
        }}
        sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
        hideConfirm={true}
      />
    </Box>
  )
}

const ChangeRole = ({
  changeRoleArgs,
  changeRoleOpen,
  setChangeRoleOpen,
}: {
  changeRoleArgs: string
  changeRoleOpen: boolean
  setChangeRoleOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation('users')
  const [role, setRole] = useState('')
  const [roleError, setRoleError] = useState(false)
  const {
    data: roles,
    error: rolesError,
    isLoading: rolesIsLoading,
  } = useGetRolesApiV1UsersRolesGetQuery()

  const {
    data: userRole,
    error: userError,
    isLoading: userLoading,
  } = useGetUserRolesApiV1UsersUserIdRolesGetQuery(
    {
      userId: encodeURI(changeRoleArgs),
    },
    { skip: changeRoleArgs === '' },
  )
  useEffect(() => {
    if (userRole && userRole.length > 0) {
      setRole(userRole[0].id)
    }
  }, [userRole])

  const { data: user } = useSearchUserApiV1UsersUserIdGetQuery(
    {
      userId: encodeURI(changeRoleArgs),
    },
    { skip: changeRoleArgs === '' },
  )

  const [removeRole] = useRemoveRoleApiV1UsersUserIdRolesRoleDeleteMutation()
  const [addRole] = useAddRoleApiV1UsersUserIdRolesRolePatchMutation()

  const changeRole = async () => {
    if (role === '') {
      setRoleError(true)
      return
    } else {
      setRoleError(false)
    }

    if (userRole) {
      try {
        if (userRole.length === 0)
          await addRole({
            userId: encodeURI(changeRoleArgs),
            role: role,
          }).unwrap()
        else if (role !== userRole[0].id) {
          await addRole({
            userId: encodeURI(changeRoleArgs),
            role: role,
          }).unwrap()
          await removeRole({
            userId: encodeURI(changeRoleArgs),
            role: userRole[0].id,
          }).unwrap()
        }
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
      setTimeout(
        () =>
          store.dispatch(
            clientApi.util.invalidateTags([
              {
                type: 'user-role',
                id: changeRoleArgs,
              },
            ]),
          ),
        REFETCH_TIMEOUT,
      ) // sometimes the auth0 database is delayed in updating data so we need to refetch after a delay to ensure data is up to date
    }
  }

  return (
    <DialogBox
      args={changeRoleArgs}
      open={changeRoleOpen}
      setOpen={setChangeRoleOpen}
      title={t('title.changeRole')}
      onClose={(approve) => {
        if (approve) changeRole()
      }}
      confirmText={t('common:button.update')}
      declineText={t('common:button.cancel')}
      message={
        <Box component="div">
          {rolesError || userError ? (
            localizeError(t, ensureError(roleError))
          ) : rolesIsLoading || userLoading ? (
            <LinearProgress />
          ) : roles && userRole ? (
            <>
              <Typography sx={{ marginBottom: '1em' }}>
                {
                  /*
                  t('users:message.changeRole')
                  t('users:message.changeRole_user')
                   */
                  t('users:message.changeRole', {
                    context: 'user',
                    ...(user?.name && { user: user?.name }),
                  })
                }
              </Typography>
              <FormControl
                component="form"
                id="role-form"
                style={{ marginBottom: '0.7em' }}
              >
                <InputLabel id="role-label" htmlFor="change-role-select">
                  {t('title.role')}
                </InputLabel>
                <Select
                  fullWidth
                  required
                  labelId="role-label"
                  label={t('title.role')}
                  id="change-role"
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                  error={roleError}
                  inputProps={{
                    id: 'change-role-select',
                  }}
                >
                  {roles.roles
                    .filter((r) => r.name === 'admin' || r.id === role)
                    .map((role) => {
                      /*
                  role keys
                  t('role.accountant')
                  t('role.admin')
                  t('role.designer')
                  t('role.owner')
                  t('role.technician')
                  */
                      return (
                        <MenuItem
                          id={`select-${role.name}`}
                          key={role.id}
                          value={role.id}
                        >
                          {t(`role.${role.name}`, { defaultValue: role.name })}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </>
          ) : null}
        </Box>
      }
      sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
    />
  )
}

const SearchBar = () => {
  const { t } = useTranslation('users')
  const searchValue =
    useSelector((state: RootState) =>
      selectQuerySearchValue(state, USER_TABLE_STATE_NAME),
    ) ?? ''

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '0.5em',
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '0.5em',
        }}
      >
        <SearchField
          id="search-users"
          placeholder={t('users:label.search')}
          value={searchValue}
          onChange={(searchInput) => {
            store.dispatch(
              setSearchValue({
                name: USER_TABLE_STATE_NAME,
                value: searchInput,
              }),
            )
            invalidateTable()
          }}
        />
      </Box>
      <ReduxPerPage
        selector={(state: RootState) =>
          state.tables.data[USER_TABLE_STATE_NAME]?.queryArgs?.perPage
        }
        dispatch={(state) =>
          store.dispatch(
            setPerPage({ name: USER_TABLE_STATE_NAME, value: state }),
          )
        }
      />
    </Box>
  )
}

const Edit = ({
  index,
  blocked,
  userId,
}: {
  index: number
  blocked: boolean
  userId: string
}) => {
  const { t } = useTranslation('users')
  const {
    enableRemoveUserButton,
    enableUnblockUserButton,
    enableChangeRoleButton,
  } = useFlags()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const { org } = useOrg()

  const [deleteUser] = useDeleteUserApiV1UsersUserIdDeleteMutation()

  const [changeRoleOpen, setChangeRoleOpen] = useState<boolean>(false)
  const [changeRoleArgs, setChangeRoleArgs] = useState<string>('')

  const [removeOpen, setRemoveOpen] = useState<boolean>(false)
  const [removeArgs, setRemoveArgs] = useState<{
    [id: string]: boolean
  }>({})
  const removeCount = Object.values(removeArgs).filter((value) => value).length

  const [removeBlock] = useRemoveBfpUserApiV1UsersUserIdBlockBfpDeleteMutation()

  const [restoreOpen, setRestoreOpen] = useState<boolean>(false)
  const [restoreArgs, setRestoreArgs] = useState<
    | {
        userId: string
      }
    | undefined
  >()

  const refetch = () => {
    store.dispatch(clientApi.util.invalidateTags(['users']))
    store.dispatch(clientApi.util.invalidateTags(['user-role']))
    store.dispatch(clientApi.util.invalidateTags(['users-bfp']))
    invalidateTable()
  }

  const deleteAccounts = async (approve: boolean) => {
    if (approve) {
      invalidateTable()
      try {
        await deleteUser({ userId: encodeURI(userId) }).unwrap()
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
      setTimeout(refetch, REFETCH_TIMEOUT) // sometimes the auth0 database is delayed in updating data so we need to refetch after a delay to ensure data is up to date
    }
  }

  const restoreAccess = async (approve: boolean, args?: { userId: string }) => {
    if (approve && args) {
      try {
        await removeBlock({ userId: encodeURI(args.userId) }).unwrap()
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    }
  }
  return (
    <>
      <IconButton
        id={`options-${index}`}
        onClick={handleMenuClick}
        sx={{ ...(openMenu && { visibility: 'visible' }) }}
      >
        <MoreMenuSvg color="inherit" />
      </IconButton>
      <Menu
        innerProps={{
          anchorEl: anchorEl,
          open: openMenu,
          onClose: handleMenuClose,
          onClick: handleMenuClose,
        }}
        hideArrow
      >
        <MenuItem
          id={`change-role-${index}`}
          onClick={() => {
            setChangeRoleArgs(userId)
            setChangeRoleOpen(true)
          }}
          disabled={!enableChangeRoleButton}
        >
          {t('button.changeRole')}
        </MenuItem>
        {blocked && (
          <MenuItem
            id={`unblock-${index}`}
            onClick={() => {
              setRestoreArgs({ userId: userId })
              setRestoreOpen(true)
            }}
            disabled={!enableUnblockUserButton}
          >
            {t('button.restoreAccess')}
          </MenuItem>
        )}
        <MenuItem
          id={`remove-${index}`}
          onClick={() => {
            setRemoveArgs({ [userId]: true })
            setRemoveOpen(true)
          }}
          disabled={!enableRemoveUserButton}
        >
          {t('common:button.remove')}
        </MenuItem>
      </Menu>
      <DialogBox
        args={removeArgs}
        open={removeOpen}
        setOpen={setRemoveOpen}
        title={t('title.remove', {
          count: removeCount,
        })}
        message={
          /*
          t('users:message.confirmDelAccounts_one')
          t('users:message.confirmDelAccounts_other')
          t('users:message.confirmDelAccounts_org_one')
          t('users:message.confirmDelAccounts_org_other')
          */
          t('users:message.confirmDelAccounts', {
            count: removeCount,
            ...(org?.name && { context: 'org', org: org?.name }),
          })
        }
        onClose={deleteAccounts}
        confirmColor="error"
        confirmText={t('common:button.remove')}
        declineText={t('common:button.cancel')}
        sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
      />
      <DialogBox
        args={restoreArgs}
        open={restoreOpen}
        setOpen={setRestoreOpen}
        title={t('title.restoreAccess')}
        message={t('message.confirmRestoreAccess')}
        onClose={restoreAccess}
        sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
      />
      <ChangeRole
        changeRoleArgs={changeRoleArgs}
        changeRoleOpen={changeRoleOpen}
        setChangeRoleOpen={setChangeRoleOpen}
      />
    </>
  )
}

const EntityCell = ({
  userId,
  column,
  index,
}: {
  userId: string
  column: Column
  index: number
}) => {
  const { t } = useTranslation('users')

  const NAME = t('title.name')
  const EMAIL = t('title.email')
  const ROLE = t('title.role')
  const EDIT = t('title.edit')
  const UNAVAILABLE = t('common:loading.unavailable')

  const encodedUserId = encodeURI(userId)
  const { error, isLoading, data } =
    useGetBfpUserApiV1UsersUserIdBlockBfpGetQuery({
      userId: encodedUserId,
    })

  const { data: userRole } = useGetUserRolesApiV1UsersUserIdRolesGetQuery({
    userId: encodedUserId,
  })
  const blocked = !error && !isLoading && data?.length !== 0
  const user = useSelector((state: RootState) =>
    selectRtkData(
      state,
      USER_TABLE_STATE_NAME,
      clientApi.endpoints.searchUsersApiV1UsersSearchGet.select,
    )?.data?.content?.find((user) => user?.user_id === userId),
  )

  return (
    <Box
      component="div"
      sx={{
        color: (theme: Theme) =>
          blocked ? theme.palette.error.main : 'inherit',
      }}
    >
      {column.name === NAME && (
        <Profile
          picture={user?.picture ?? UNAVAILABLE}
          name={user?.name ?? UNAVAILABLE}
        />
      )}
      {column.name === EMAIL && (user?.email ?? UNAVAILABLE)}
      {column.name === ROLE &&
        // TODO get role of user
        /*
          role keys
          t('role.accountant')
          t('role.admin')
          t('role.designer')
          t('role.owner')
          t('role.technician')
          */
        (userRole && userRole.length > 0
          ? t(`role.${userRole[0].name}`, { defaultValue: UNAVAILABLE })
          : UNAVAILABLE)}

      {column.name === EDIT && (
        <Edit index={index} blocked={blocked} userId={userId} />
      )}
    </Box>
  )
}

const USER_TABLE_STATE_NAME = 'usersTableState'

const UsersListData = () => {
  const queryArgs = useSelector((state: RootState) =>
    selectQueryArgs(state, USER_TABLE_STATE_NAME),
  )

  const rtkArgs = useMemo(() => {
    return {
      page: queryArgs.page !== 0 ? queryArgs.page - 1 : queryArgs.page,
      per_page: queryArgs.perPage,
      query:
        queryArgs.searchValue !== ''
          ? `email:${queryArgs.searchValue}* OR name:"*${queryArgs.searchValue}*"`
          : undefined,
      sorting:
        queryArgs.sortField !== ''
          ? queryArgs.sortField +
            ':' +
            (queryArgs.sortOrder === 'asc' ? '1' : '-1')
          : undefined,
    }
  }, [queryArgs])

  useEffect(() => {
    store.dispatch(setRtkArgs({ name: USER_TABLE_STATE_NAME, value: rtkArgs }))
  }, [rtkArgs])

  const { isFetching } = useSearchUsersApiV1UsersSearchGetQuery(rtkArgs, {})

  return (
    <>
      {isFetching && (
        <div id="loading-users" style={{ display: 'hidden' }}></div>
      )}
    </>
  )
}

const UsersList = () => {
  const { t } = useTranslation('users')

  const { allowUserManagementUi } = useFlags()

  const NAME = t('title.name')
  const EMAIL = t('title.email')
  const ROLE = t('title.role')
  const EDIT = t('title.edit')

  const SortFields = ['email', 'name', 'role']

  const theme = useTheme()

  const isSearching = useSelector((state: RootState) =>
    selectQueryIsSearching(state, USER_TABLE_STATE_NAME),
  )
  const selectUserIds = useMemo(() => {
    const emptyArray: UserReadDto[] = []

    return createSelector(
      [(res?: UsersPaginatedListRead) => res?.content ?? emptyArray],
      (content) =>
        content
          .map((user) => user?.user_id)
          .filter((userId) => userId != null) as string[],
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const data = useSelector((state: RootState) =>
    selectUserIds(
      selectRtkData(
        state,
        USER_TABLE_STATE_NAME,
        clientApi.endpoints.searchUsersApiV1UsersSearchGet.select,
      )?.data,
    ),
  )
  const totalCount = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      USER_TABLE_STATE_NAME,
      clientApi.endpoints.searchUsersApiV1UsersSearchGet.select,
    )
    return query?.data?.total_count ?? query?.data?.content?.length
  })
  const error = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        USER_TABLE_STATE_NAME,
        clientApi.endpoints.searchUsersApiV1UsersSearchGet.select,
      )?.error,
  )
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      USER_TABLE_STATE_NAME,
      clientApi.endpoints.searchUsersApiV1UsersSearchGet.select,
    )

    return query?.isLoading && query?.isUninitialized
  })

  const {
    data: totalData,
    error: totalError,
    isLoading: totalIsLoading,
  } = useSearchUsersApiV1UsersSearchGetQuery(
    {},
    { skip: !allowUserManagementUi },
  )

  const columns: Column[] = useMediaQuery(theme.breakpoints.up('sm'))
    ? [
        { name: NAME, sortable: true, key: SortFields[1] },
        { name: EMAIL, sortable: true, key: SortFields[0] },
        { name: ROLE, sortable: false },
        { name: EDIT, sortable: false, hover: true },
      ]
    : [
        { name: NAME, sortable: true, key: SortFields[1] },
        { name: EMAIL, sortable: true, key: SortFields[0] },
        { name: EDIT, sortable: false },
      ]
  const checkable = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      <UsersListData />
      {error || totalError ? (
        <>{localizeError(t, ensureError(error ? error : totalError))}</>
      ) : isLoading || totalIsLoading || allowUserManagementUi === undefined ? (
        <LinearProgress />
      ) : (
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            pt: '0.2em',
          }}
        >
          {(totalData?.total_count ?? 0) > 1 ? (
            <>
              <SearchBar />
              <DynamicTable
                stateName={USER_TABLE_STATE_NAME}
                checkable={checkable}
                columns={columns}
                tableData={data}
                totalNumEntities={totalCount ?? 0}
                onSort={(sort) => {
                  if (sort !== undefined && sort.key !== undefined) {
                    store.dispatch(
                      setSortField({
                        name: USER_TABLE_STATE_NAME,
                        value: sort.key,
                      }),
                    )
                    store.dispatch(
                      setSortOrder({
                        name: USER_TABLE_STATE_NAME,
                        value: sort.order,
                      }),
                    )
                  } else {
                    store.dispatch(
                      setSortField({
                        name: USER_TABLE_STATE_NAME,
                        value: SortFields[1],
                      }),
                    )
                    store.dispatch(
                      setSortOrder({
                        name: USER_TABLE_STATE_NAME,
                        value: 'asc',
                      }),
                    )
                  }
                }}
                noMatch={isSearching && totalCount === 0}
                renderEntry={(user: string, userIndex: number) =>
                  columns.map((column, index) => (
                    <EntityCell
                      key={index}
                      column={column}
                      userId={user}
                      index={userIndex}
                    />
                  ))
                }
                menuOptions={[]}
                sx={{
                  svg: {
                    color: 'primary.main',
                  },
                }}
                mapId={(user) => user}
              />
            </>
          ) : (
            <NoUsers />
          )}
        </Box>
      )}
    </>
  )
}

export { UsersList, USER_TABLE_STATE_NAME }
