import React, { FC } from 'react'
import {
  invalidate,
  invalidateDetailsItem,
  selectNumChecked,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { ActionBar } from '../ActionBar/ActionBar'
import { PART_TABLE_STATE_NAMES } from '../PartsTable/PartsTable'
import { PartButtonGroup, PartButtonGroupType } from './buttons'

interface InboxActionBarProps {
  table: PART_TABLE_STATE_NAMES
  type: PartButtonGroupType
  isInspectorPanel?: boolean
}

export const PartActionBar: FC<InboxActionBarProps> = ({
  table,
  type,
  isInspectorPanel = false,
}) => {
  const invalidateTable = () => {
    store.dispatch(invalidate(table))
    store.dispatch(invalidateDetailsItem(table))
  }

  return (
    <ActionBar
      handleCloseActionBar={invalidateTable}
      selector={(state: RootState) => selectNumChecked(state, table)}
    >
      <PartButtonGroup
        table={table}
        isInspectorPanel={isInspectorPanel}
        type={type}
      />
    </ActionBar>
  )
}
