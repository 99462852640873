import {
  Box,
  IconButton,
  Pagination,
  Paper,
  SvgIcon,
  SxProps,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import Table from '@mui/material/Table'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Ordering } from '../../helpers'
import {
  invalidate,
  removeDeleted,
  selectCanCollapse,
  selectCanCollapseRow,
  selectChecked,
  selectCollapsible,
  selectQueryPage,
  selectQueryPerPage,
  selectQuerySortField,
  selectQuerySortOrder,
  selectTableChecked,
  selectTableCollapsible,
  setPage,
  setSortField,
  setSortOrder,
  setTableChecked,
  toggleChecked,
  toggleCollapsible,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { Button } from '../Button/Button'
import { Checkbox } from '../Checkbox/Checkbox'
import {
  CollapsedSvg,
  ExpandedSvg,
  SortAscendSvg,
  SortDescendSvg,
  SortSvg,
} from '../Icon/Icon'

type MenuOption = {
  event: (event: React.MouseEvent<HTMLElement>) => Promise<void>
  name: string
  hideName?: boolean
  icon?: typeof SvgIcon
  variant?: 'outlined' | 'contained' | 'text'
}

type Sort = {
  column: string
  key?: string
  order: Ordering
}

type Column = {
  name: string
  key?: string
  sortable?: boolean
  hover?: boolean
}

interface DynamicTableProps<T> {
  stateName: string
  columns: Column[]
  renderEntry: (entry: T, index: number) => React.ReactNode[]
  renderCollapsible?: (
    visible: boolean,
    entry: T,
    selected: boolean,
    index: number,
  ) => React.JSX.Element | React.JSX.Element[] | null
  checkable: boolean
  menuOptions?: MenuOption[]
  tableData: T[]
  totalNumEntities: number
  sx?: SxProps<Theme>
  onSort?: (sort: Sort | undefined) => void
  noMatch?: boolean
  selectable?: boolean
  mapId: (entry: T) => string
}

interface RowProps<T> {
  entry: T
  index: number
  mapId: (entry: T) => string
  stateName: string
  checkable: boolean
  renderEntry: (entry: T, index: number) => React.ReactNode[]
  renderCollapsible?: (
    visible: boolean,
    entry: T,
    selected: boolean,
    index: number,
  ) => React.JSX.Element | React.JSX.Element[] | null
  selectable?: boolean
  columns: Column[]
  checkRange: (start: T) => void
}

const NoSearchResult = () => {
  const { t } = useTranslation('common')

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: '5em',
      }}
    >
      <img
        alt={t('alt.noSearchResults')}
        style={{ maxWidth: '240px' }}
        src="/images/no-search-results.svg"
      />
      <Typography textAlign={'center'} margin={'1em 0'} marginTop={'2.5em'}>
        {t('message.noMatches')}
      </Typography>
      <Typography component="pre" textAlign={'center'}>
        {t('message.noMatchesHelp')}
      </Typography>
    </Box>
  )
}

const Row = <T,>({
  entry,
  index,
  mapId,
  stateName,
  renderCollapsible,
  selectable,
  checkable,
  renderEntry,
  columns,
  checkRange,
}: RowProps<T>) => {
  const { t } = useTranslation()
  const id = mapId(entry)
  const canCollapseRow =
    useSelector((state: RootState) =>
      selectCanCollapseRow(state, stateName, id),
    ) ?? false
  const canCollapse = useSelector((state: RootState) =>
    selectCanCollapse(state, stateName),
  )
  const collapsibleState = useSelector((state: RootState) =>
    selectCollapsible(state, stateName, id),
  )

  const checkedState = useSelector((state: RootState) =>
    selectChecked(state, stateName, id),
  )

  const Collapsible = ({ entry }: CollapsibleProps<T>) => {
    return (
      <>
        {renderCollapsible &&
          renderCollapsible(collapsibleState, entry, checkedState, index)}
      </>
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.stopPropagation() // Stop propagation to prevent row click

    if (selectable) {
      if (event.ctrlKey)
        store.dispatch(toggleChecked({ name: stateName, checked: id }))
      else if (event.shiftKey) {
        checkRange(entry)
      } else
        store.dispatch(
          setTableChecked({
            name: stateName,
            checked: { [id]: true },
            lastChecked: id,
          }),
        )
    }
  }

  return (
    <Fragment>
      <TableRow
        key={`entry-${index}`}
        sx={{
          '&& > * .MuiCheckbox-root': {
            visibility: checkedState ? 'visible' : 'hidden',
          },
          '&&:hover > * .MuiCheckbox-root': { visibility: 'visible' },
          '&& > * .hidden-cell': {
            visibility: 'hidden',
          },
          '&&:hover > * .hidden-cell': { visibility: 'visible' },
          ...(selectable && { cursor: 'pointer' }),
        }}
        hover
        onClick={handleClick}
        selected={checkedState}
      >
        {canCollapse && (
          <TableCell padding="none">
            {canCollapseRow && (
              <IconButton
                aria-label={t('common:table.expandRow')}
                size="small"
                onClick={(e: React.SyntheticEvent) => {
                  e.stopPropagation()
                  store.dispatch(
                    toggleCollapsible({
                      name: stateName,
                      collapsible: id,
                    }),
                  )
                }}
              >
                {collapsibleState ? (
                  <Box component={'div'} sx={{ transform: 'rotate(180deg)' }}>
                    <ExpandedSvg color="inherit" />
                  </Box>
                ) : (
                  <CollapsedSvg color="inherit" />
                )}
              </IconButton>
            )}
          </TableCell>
        )}
        {checkable && (
          <TableCell padding="checkbox">
            <Checkbox
              id={`checked-${index}`}
              checked={checkedState}
              onClick={(e) => {
                e.stopPropagation()
                if (e.shiftKey) {
                  checkRange(entry)
                  return
                }
                store.dispatch(
                  toggleChecked({
                    name: stateName,
                    checked: id,
                  }),
                )
              }}
            />
          </TableCell>
        )}
        {renderEntry(entry, index).map((cell, cellIndex) => {
          return (
            <TableCell
              key={`cell${cellIndex}`}
              style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
            >
              <div
                className={columns[cellIndex].hover ? 'hidden-cell' : undefined}
                id={`row-${index}-cell-${cellIndex}`}
              >
                {cell}
              </div>
            </TableCell>
          )
        })}
      </TableRow>
      {canCollapse && <Collapsible entry={entry} />}
    </Fragment>
  )
}

const Title = ({
  column,
  index,
  onSort,
  stateName,
}: {
  column: Column
  index: number
  stateName: string
  onSort?: (sort: Sort | undefined) => void
}) => {
  const sortField = useSelector((state: RootState) =>
    selectQuerySortField(state, stateName),
  )
  const sortOrder = useSelector((state: RootState) =>
    selectQuerySortOrder(state, stateName),
  )

  const handleOnSort = () => {
    let field = undefined
    let order: Ordering = 'asc'

    if (sortField === column.key) {
      if (sortOrder === 'asc') {
        field = column.key
        order = 'desc'
      } else {
        field = undefined
      }
    } else {
      field = column.key ?? ''
      order = 'asc'
    }

    if (onSort === undefined) {
      store.dispatch(setSortField({ name: stateName, value: field ?? '' }))
      store.dispatch(setSortOrder({ name: stateName, value: order }))
      return
    }

    if (field !== undefined) {
      onSort?.({
        column: column.name,
        key: column.key,
        order,
      })
    } else {
      onSort?.(undefined)
    }
  }

  return (
    <TableCell
      key={`column-${index}`}
      sx={{
        ...(column.sortable
          ? {
              '&& > * .MuiButtonBase-root': {
                visibility: sortField !== column.key ? 'hidden' : 'visible',
              },
              '&&:hover > * .MuiButtonBase-root': {
                visibility: 'visible',
              },
            }
          : {
              '&& > * .MuiButtonBase-root': {
                visibility: 'hidden',
              },
            }),
      }}
    >
      <div style={{ visibility: column.hover ? 'hidden' : 'visible' }}>
        {column.name}
        <IconButton
          sx={{
            height: '24px',
            padding: '0px',
            marginLeft: '4px',
          }}
          id={`sort-by-${column.name}`}
          onClick={handleOnSort}
        >
          {sortField !== column.key ? (
            <SortSvg color="inherit" />
          ) : sortOrder === 'asc' ? (
            <SortAscendSvg color="inherit" />
          ) : (
            <SortDescendSvg color="inherit" />
          )}
        </IconButton>
      </div>
    </TableCell>
  )
}

const Header = <T,>({
  stateName,
  checkable,
  columns,
  tableData,
  mapId,
  onSort,
}: {
  stateName: string
  checkable: boolean
  columns: Column[]
  tableData: T[]
  onSort?: (sort: Sort | undefined) => void
  mapId: (entry: T) => string
}) => {
  const checked = useSelector((state: RootState) =>
    selectTableChecked(state, stateName),
  )
  const canCollapse = useSelector((state: RootState) =>
    selectCanCollapse(state, stateName),
  )
  const numChecked = Object.values(checked).filter((value) => value).length
  const someChecked = numChecked > 0
  const allChecked = numChecked === tableData.length && tableData.length !== 0
  return (
    <TableHead>
      <TableRow
        sx={{
          '&& > * .MuiCheckbox-root': {
            display: allChecked || someChecked ? 'block' : 'none',
          },
          '&&:hover > * .MuiCheckbox-root': { display: 'block' },
        }}
      >
        {canCollapse && <TableCell padding="checkbox"></TableCell>}
        {checkable && (
          <TableCell padding="checkbox">
            <Checkbox
              id={`table-all-checked`}
              checked={allChecked}
              indeterminate={!allChecked && someChecked}
              onClick={() => {
                if (allChecked)
                  store.dispatch(
                    setTableChecked({
                      name: stateName,
                      checked: Object.assign(
                        {},
                        ...Object.entries(checked).map(([key]) => {
                          return { [key]: false }
                        }),
                      ),
                      lastChecked: undefined,
                    }),
                  )
                else
                  store.dispatch(
                    setTableChecked({
                      name: stateName,
                      checked: Object.assign(
                        {},
                        ...tableData.map((entry) => {
                          return { [mapId(entry)]: true }
                        }),
                      ),
                      lastChecked: undefined,
                    }),
                  )
              }}
            />
          </TableCell>
        )}
        {columns.map((column, index) => {
          return (
            <Title
              key={`header-${index}`}
              column={column}
              index={index}
              onSort={onSort}
              stateName={stateName}
            />
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const TableMenuItem = ({
  menu,
  stateName,
}: {
  menu: MenuOption
  stateName: string
}) => {
  const Icon = menu.icon
  return (
    <div>
      {menu.hideName && Icon ? (
        <IconButton
          onClick={async (e: React.MouseEvent<HTMLElement>) => {
            await menu.event(e)
            store.dispatch(
              setTableChecked({
                name: stateName,
                checked: {},
                lastChecked: undefined,
              }),
            )
          }}
          sx={{ height: '2.5em' }}
          aria-label={menu.name}
        >
          <Icon />
        </IconButton>
      ) : (
        <Button
          onClick={async (e: React.MouseEvent<HTMLElement>) => {
            await menu.event(e)
            store.dispatch(
              setTableChecked({
                name: stateName,
                checked: {},
                lastChecked: undefined,
              }),
            )
          }}
          aria-label={menu.name}
          variant={menu.variant ? menu.variant : 'outlined'}
          sx={{ height: '2.5em' }}
        >
          {Icon && <Icon />}
          {menu.name}
        </Button>
      )}
    </div>
  )
}

interface CollapsibleProps<T> {
  entry: T
}

const DynamicTableUpdates = <T,>({
  stateName,
  tableData,
  mapId,
}: {
  mapId: (entry: T) => string
  stateName: string
  tableData: T[]
}) => {
  const checked = useSelector((state: RootState) =>
    selectTableChecked(state, stateName),
  )
  const collapsible = useSelector((state: RootState) =>
    selectTableCollapsible(state, stateName),
  )

  useEffect(() => {
    const ids = new Set(tableData.map((entry) => mapId(entry)))
    const deletedChecked = Object.keys(checked).filter((id) => !ids.has(id))
    const deletedCollapsible = Object.keys(collapsible).filter(
      (id) => !ids.has(id),
    )
    const deleted = [...new Set([...deletedChecked, ...deletedCollapsible])]
    if (deleted.length > 0)
      store.dispatch(removeDeleted({ name: stateName, ids: deleted }))
  }, [tableData, checked, collapsible, stateName, mapId])
  return <>{null}</>
}

const DynamicTable = <T,>(props: DynamicTableProps<T>) => {
  const {
    tableData,
    totalNumEntities,
    columns,
    selectable = false,
    stateName,
  } = props
  const page = useSelector((state: RootState) =>
    selectQueryPage(state, stateName),
  )
  const perPage = useSelector((state: RootState) =>
    selectQueryPerPage(state, stateName),
  )

  const pages = Math.ceil(totalNumEntities / perPage)

  useEffect(() => {
    if (page > pages) store.dispatch(setPage({ name: stateName, value: pages }))
    else if (pages !== 0 && page === 0)
      store.dispatch(setPage({ name: stateName, value: 1 }))
  }, [page, pages, stateName])

  const checkRange = (entry: T) => {
    const checked = props.mapId(entry)
    const state = store.getState()
    const lastChecked = state.tables.data[stateName].lastChecked

    if (lastChecked === undefined || lastChecked === checked) {
      store.dispatch(
        setTableChecked({
          name: stateName,
          checked: { [checked]: true },
          lastChecked: checked,
        }),
      )
      return
    }

    const toCheck: { [id: string]: boolean } = {}
    let insideRange = false
    for (const item of tableData) {
      const id = props.mapId(item)
      if (id === lastChecked || id === checked) {
        if (insideRange) break
        insideRange = true
      }

      if (insideRange) toCheck[id] = true
    }

    toCheck[lastChecked] = true
    toCheck[checked] = true

    store.dispatch(
      setTableChecked({
        name: stateName,
        checked: toCheck,
      }),
    )
  }

  return (
    <>
      <DynamicTableUpdates
        stateName={stateName}
        mapId={props.mapId}
        tableData={tableData}
      />
      <Box
        component="div"
        sx={{
          flexGrow: '1',
          flexShrink: '1',
          overflow: 'auto',
          position: 'relative',
          ...(props.sx ?? {}),
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            ...(props.sx ?? {}),
          }}
        >
          {props?.menuOptions && (
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: ['0.6em'],
                gap: '0.3em',
                width: '100%',
              }}
            >
              {props.menuOptions.map((option, index) => {
                return (
                  <TableMenuItem
                    key={`option-${index}`}
                    stateName={stateName}
                    menu={option}
                  />
                )
              })}
            </Box>
          )}
        </Box>

        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ width: '100%', bgcolor: 'inherit' }}
        >
          <Table>
            <Header
              stateName={stateName}
              checkable={props.checkable}
              columns={columns}
              tableData={tableData}
              mapId={props.mapId}
              onSort={props.onSort}
            />
            <TableBody sx={{ '&& > tr td': { userSelect: 'none' } }}>
              {tableData.map((entry, index) => (
                <Row
                  key={`row-${index}`}
                  entry={entry}
                  index={index}
                  mapId={props.mapId}
                  stateName={stateName}
                  renderCollapsible={props.renderCollapsible}
                  renderEntry={props.renderEntry}
                  selectable={selectable}
                  columns={columns}
                  checkable={props.checkable}
                  checkRange={checkRange}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {props.noMatch && <NoSearchResult />}

        {pages !== 0 && (
          <Box
            component="div"
            sx={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}
          >
            <Pagination
              count={pages}
              page={page}
              onChange={(_, value) => {
                store.dispatch(invalidate(stateName))
                store.dispatch(setPage({ name: stateName, value: value }))
              }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export type { MenuOption, Column, Sort }
export { DynamicTable }
