import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Ordering, perPageAmounts } from '../../helpers'
import { RootState } from '../store'

const EMPTY_CHECKED_IDS: string[] = []
const EMPTY_CHECKED_COLLAPSIBLE: { [index: string]: boolean } = {}

const DEFAULT_QUERY_DATA: QueryArgs = {
  page: 1,
  perPage: perPageAmounts[0],
  searchValue: '',
  sortField: '',
  sortOrder: 'asc',
}
const DEFAULT_TABLE_DATA: TableData = {
  checked: {},
  collapsible: {},
  checkedIds: [],
  numChecked: 0,
  rtkArgs: undefined,
  prevRtkArgs: undefined,
  queryArgs: { ...DEFAULT_QUERY_DATA },
  namedQueryArgs: {},
  detailsItem: undefined,
}

const getQueryArgs = (table: TableData) => {
  if (table?.selectedQueryArgs) {
    return (
      table?.namedQueryArgs?.[table?.selectedQueryArgs] ?? DEFAULT_QUERY_DATA
    )
  } else return table?.queryArgs ?? DEFAULT_QUERY_DATA
}

const initialState: TableState = { data: {} } satisfies TableState
const tableSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setTableChecked(
      state: TableState,
      action: PayloadAction<{
        name: string
        checked: { [index: string]: boolean }
        lastChecked?: string
      }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }

      const checked = action.payload.checked
      const checkedIds = Object.entries(checked)
        .filter(([id, selected]) => selected)
        .map(([id]) => id.toString())
        .sort()

      state.data[action.payload.name].checked = checked
      state.data[action.payload.name].numChecked = checkedIds.length
      state.data[action.payload.name].checkedIds = checkedIds
      // If only one row is selected, store its ID in detailsItem
      if (checkedIds.length === 1) {
        state.data[action.payload.name].detailsItem = checkedIds[0]
      }

      state.data[action.payload.name].lastChecked = action.payload.lastChecked
    },
    setTableCollapsible(
      state: TableState,
      action: PayloadAction<{
        name: string
        collapsible: { [index: string]: boolean }
      }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      state.data[action.payload.name].collapsible = action.payload.collapsible
    },
    removeDeleted(
      state: TableState,
      action: PayloadAction<{
        name: string
        ids: string[]
      }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      for (const id of action.payload.ids) {
        delete state.data[action.payload.name]?.collapsible?.[id]
        delete state.data[action.payload.name]?.checked?.[id]
      }

      state.data[action.payload.name].numChecked = Object.values(
        state.data[action.payload.name].checked ?? {},
      ).filter((selected) => selected).length
      state.data[action.payload.name].checkedIds = Object.entries(
        state.data[action.payload.name].checked ?? {},
      )
        .filter(([id, selected]) => selected)
        .map(([id]) => id.toString())
        .sort()

      if (
        action.payload.ids.includes(
          state.data[action.payload.name].lastChecked ?? '',
        )
      )
        state.data[action.payload.name].lastChecked = undefined
    },
    ensureTableExists(state: TableState, action: PayloadAction<string>) {
      if (state.data[action.payload] === undefined) {
        state.data[action.payload] = { ...DEFAULT_TABLE_DATA }
      }
    },
    invalidate(state: TableState, action: PayloadAction<string>) {
      if (state.data[action.payload] === undefined)
        state.data[action.payload] = { ...DEFAULT_TABLE_DATA }
      state.data[action.payload].checked = {}
      state.data[action.payload].collapsible = {}
      state.data[action.payload].numChecked = 0
      state.data[action.payload].checkedIds = []
      state.data[action.payload].lastChecked = undefined
    },
    toggleChecked(
      state: TableState,
      action: PayloadAction<{ name: string; checked: string }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }

      // Toggle the checked status of the specific item
      state.data[action.payload.name].checked = {
        ...state.data[action.payload.name].checked,
        [action.payload.checked]:
          !state.data[action.payload.name].checked?.[action.payload.checked],
      }

      // Update the count of checked items and the list of checked IDs
      const checkedIds = Object.entries(
        state.data[action.payload.name].checked ?? {},
      )
        .filter(([id, selected]) => selected)
        .map(([id]) => id.toString())
        .sort()

      state.data[action.payload.name].numChecked = checkedIds.length
      state.data[action.payload.name].checkedIds = checkedIds

      // If only one item is selected, store its ID in detailsItem and ensure it is checked
      if (checkedIds.length === 1) {
        state.data[action.payload.name].detailsItem = checkedIds[0]
      } else if (checkedIds.length === 0) {
        state.data[action.payload.name].detailsItem = undefined
      }

      if (state.data[action.payload.name].checked?.[action.payload.checked])
        state.data[action.payload.name].lastChecked = action.payload.checked
    },
    toggleCollapsible(
      state: TableState,
      action: PayloadAction<{ name: string; collapsible: string }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      state.data[action.payload.name].collapsible = {
        ...state.data[action.payload.name].collapsible,
        [action.payload.collapsible]:
          !state.data[action.payload.name].collapsible?.[
            action.payload.collapsible
          ],
      }
    },
    setChecked(
      state: TableState,
      action: PayloadAction<{ name: string; checked: string; value: boolean }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      state.data[action.payload.name].checked = {
        ...state.data[action.payload.name]?.checked,
        [action.payload.checked]: action.payload.value,
      }
      state.data[action.payload.name].numChecked = Object.values(
        state.data[action.payload.name].checked ?? {},
      ).filter((selected) => selected).length
      state.data[action.payload.name].checkedIds = Object.entries(
        state.data[action.payload.name].checked ?? {},
      )
        .filter(([id, selected]) => selected)
        .map(([id]) => id.toString())
        .sort()
      if (
        action.payload.checked === state.data[action.payload.name].lastChecked
      )
        state.data[action.payload.name].lastChecked = action.payload.value
          ? action.payload.checked
          : undefined
    },
    setCollapsible(
      state: TableState,
      action: PayloadAction<{
        name: string
        collapsible: string
        value: boolean
      }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      state.data[action.payload.name].collapsible = {
        ...state.data[action.payload.name]?.collapsible,
        [action.payload.collapsible]: action.payload.value,
      }
    },
    setSelectedQueryArgs(
      state,
      action: PayloadAction<{ name: string; queryArgs?: string }>,
    ) {
      state.data[action.payload.name].selectedQueryArgs =
        action.payload.queryArgs
    },
    setPage(state, action: PayloadAction<{ name: string; value: number }>) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      if (state.data[action.payload.name]?.selectedQueryArgs !== undefined) {
        state.data[action.payload.name].namedQueryArgs = {
          ...state.data[action.payload.name].namedQueryArgs,
          [state.data[action.payload.name].selectedQueryArgs ?? '']: {
            ...DEFAULT_QUERY_DATA,
            ...(state.data[action.payload.name].namedQueryArgs?.[
              state.data[action.payload.name].selectedQueryArgs as any
            ] ?? {}),
            page: action.payload.value,
          },
        }
      } else {
        state.data[action.payload.name].queryArgs = {
          ...DEFAULT_QUERY_DATA,
          ...state.data[action.payload.name].queryArgs,
          page: action.payload.value,
        }
      }
    },
    setPerPage(state, action: PayloadAction<{ name: string; value: number }>) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      if (state.data[action.payload.name]?.selectedQueryArgs !== undefined) {
        state.data[action.payload.name].namedQueryArgs = {
          ...state.data[action.payload.name].namedQueryArgs,
          [state.data[action.payload.name].selectedQueryArgs ?? '']: {
            ...DEFAULT_QUERY_DATA,
            ...(state.data[action.payload.name].namedQueryArgs?.[
              state.data[action.payload.name].selectedQueryArgs as any
            ] ?? {}),
            perPage: action.payload.value,
          },
        }
      } else {
        state.data[action.payload.name].queryArgs = {
          ...DEFAULT_QUERY_DATA,
          ...state.data[action.payload.name].queryArgs,
          perPage: action.payload.value,
        }
      }
    },
    setSearchValue(
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      if (state.data[action.payload.name]?.selectedQueryArgs !== undefined) {
        state.data[action.payload.name].namedQueryArgs = {
          ...state.data[action.payload.name].namedQueryArgs,
          [state.data[action.payload.name].selectedQueryArgs ?? '']: {
            ...DEFAULT_QUERY_DATA,
            ...(state.data[action.payload.name].namedQueryArgs?.[
              state.data[action.payload.name].selectedQueryArgs as any
            ] ?? {}),
            searchValue: action.payload.value,
          },
        }
      } else {
        state.data[action.payload.name].queryArgs = {
          ...DEFAULT_QUERY_DATA,
          ...state.data[action.payload.name].queryArgs,
          searchValue: action.payload.value,
        }
      }
    },
    setSortField(
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      if (state.data[action.payload.name]?.selectedQueryArgs !== undefined) {
        state.data[action.payload.name].namedQueryArgs = {
          ...state.data[action.payload.name].namedQueryArgs,
          [state.data[action.payload.name].selectedQueryArgs ?? '']: {
            ...DEFAULT_QUERY_DATA,
            ...(state.data[action.payload.name].namedQueryArgs?.[
              state.data[action.payload.name].selectedQueryArgs as any
            ] ?? {}),
            sortField: action.payload.value,
          },
        }
      } else {
        state.data[action.payload.name].queryArgs = {
          ...DEFAULT_QUERY_DATA,
          ...state.data[action.payload.name].queryArgs,
          sortField: action.payload.value,
        }
      }
    },
    setSortOrder(
      state,
      action: PayloadAction<{ name: string; value: Ordering }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      if (state.data[action.payload.name]?.selectedQueryArgs !== undefined) {
        state.data[action.payload.name].namedQueryArgs = {
          ...state.data[action.payload.name].namedQueryArgs,
          [state.data[action.payload.name].selectedQueryArgs ?? '']: {
            ...DEFAULT_QUERY_DATA,
            ...(state.data[action.payload.name].namedQueryArgs?.[
              state.data[action.payload.name].selectedQueryArgs as any
            ] ?? {}),
            sortOrder: action.payload.value,
          },
        }
      } else {
        state.data[action.payload.name].queryArgs = {
          ...DEFAULT_QUERY_DATA,
          ...state.data[action.payload.name].queryArgs,
          sortOrder: action.payload.value,
        }
      }
    },
    setRtkArgs(state, action: PayloadAction<{ name: string; value: any }>) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }

      state.data[action.payload.name].prevRtkArgs =
        state.data[action.payload.name].rtkArgs
      state.data[action.payload.name].rtkArgs = action.payload.value
    },
    enableCollapsible(
      state,
      action: PayloadAction<{ name: string; index: string; value: boolean }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      if (state.data[action.payload.name].canCollapse === undefined)
        state.data[action.payload.name].canCollapse = {}
    },
    disableCollapsible(
      state,
      action: PayloadAction<{ name: string; index: string; value: boolean }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      if (state.data[action.payload.name].canCollapse !== undefined)
        state.data[action.payload.name].canCollapse = undefined
    },
    setCanCollapsible(
      state,
      action: PayloadAction<{ name: string; index: string; value: boolean }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      if (state.data[action.payload.name].canCollapse === undefined)
        state.data[action.payload.name].canCollapse = {}
      state.data[action.payload.name].canCollapse = {
        ...state.data[action.payload.name]?.canCollapse,
        [action.payload.index]: action.payload.value,
      }
    },
    invalidateCanCollapsible(state, action: PayloadAction<{ name: string }>) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      state.data[action.payload.name].canCollapse = {}
    },
    // Add actions to set and clear detailsItem
    setDetailsItem(
      state: TableState,
      action: PayloadAction<{ name: string; id: string | undefined }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { ...DEFAULT_TABLE_DATA }
      state.data[action.payload.name].detailsItem = action.payload.id
    },

    invalidateDetailsItem(state: TableState, action: PayloadAction<string>) {
      if (state.data[action.payload] === undefined)
        state.data[action.payload] = { ...DEFAULT_TABLE_DATA }
      state.data[action.payload].detailsItem = undefined
    },
  },
  selectors: {
    selectQueryArgs: (state, table: string) => getQueryArgs(state.data[table]),
    selectQuerySearchValue: (state, table: string) =>
      getQueryArgs(state.data[table]).searchValue ?? '',
    selectQueryPage: (state, table: string) =>
      getQueryArgs(state.data[table]).page ?? 1,
    selectQueryPerPage: (state, table: string) =>
      getQueryArgs(state.data[table]).perPage ?? perPageAmounts[0],
    selectQueryIsSearching: (state, table: string) =>
      (getQueryArgs(state.data[table]).searchValue ?? '') !== '',
    selectQuerySortField: (state, table: string) =>
      getQueryArgs(state.data[table]).sortField,
    selectQuerySortOrder: (state, table: string) =>
      getQueryArgs(state.data[table]).sortOrder,
    selectNumChecked: (state, table: string) =>
      state.data[table]?.numChecked ?? 0,
    selectCheckedIds: (state, table: string) =>
      state.data[table]?.checkedIds ?? EMPTY_CHECKED_IDS,
    selectFirstCheckedId: (state, table: string) =>
      state.data[table]?.checkedIds?.[0],
    selectIsOneSelected: (state, table: string) =>
      state.data[table]?.numChecked === 1,
    selectIsChecked: (state, table: string) =>
      (state.data[table]?.numChecked ?? 0) > 0,
    selectCanCollapseRow: (state, table: string, id: string) =>
      state.data[table]?.canCollapse?.[id],
    selectCanCollapse: (state, table: string) =>
      state.data[table]?.canCollapse !== undefined,
    selectCollapsible: (state, table: string, id: string) =>
      state.data[table]?.collapsible?.[id] ?? false,
    selectChecked: (state, table: string, id: string) =>
      state.data[table]?.checked?.[id] ?? false,
    selectShouldQueryFieldBeSorted: (state, table: string, field?: string) =>
      getQueryArgs(state.data[table]).sortField === field,
    selectQuerySortBy: (
      state,
      table: string,
      sortField: string,
    ): Ordering | undefined =>
      getQueryArgs(state.data[table]).sortField === sortField
        ? getQueryArgs(state.data[table]).sortOrder
        : undefined,
    selectTableChecked: (state, table: string) =>
      state.data[table]?.checked ?? EMPTY_CHECKED_COLLAPSIBLE,
    selectTableCollapsible: (state, table: string) =>
      state.data[table]?.collapsible ?? EMPTY_CHECKED_COLLAPSIBLE,
    selectSelectedQueryArgs: (state, table: string) =>
      state.data[table]?.selectedQueryArgs,
    selectDetailsItem: (state, table: string) => state.data[table]?.detailsItem,
  },
})

const selectRtkData = <T extends { data?: unknown; isLoading?: boolean }>(
  state: RootState,
  table: string,
  select: (rtkArg: any) => (state: RootState) => T,
) => {
  const rtkArgs = state.tables.data[table]?.rtkArgs
  if (rtkArgs === undefined) return undefined

  const rtkState = select(rtkArgs)(state)

  if (
    state.tables.data[table]?.prevRtkArgs !== undefined &&
    rtkState?.data === undefined &&
    rtkState?.isLoading
  ) {
    return select(state.tables.data[table]?.prevRtkArgs)(state)
  }

  return rtkState
}

export const {
  setTableChecked,
  setTableCollapsible,
  ensureTableExists,
  toggleCollapsible,
  toggleChecked,
  setChecked,
  setCollapsible,
  invalidate,
  setSelectedQueryArgs,
  setPage,
  setPerPage,
  setSearchValue,
  setSortField,
  setSortOrder,
  setRtkArgs,
  enableCollapsible,
  disableCollapsible,
  setCanCollapsible,
  invalidateCanCollapsible,
  removeDeleted,
  setDetailsItem,
  invalidateDetailsItem,
} = tableSlice.actions
export const {
  selectCheckedIds,
  selectQueryArgs,
  selectQuerySearchValue,
  selectNumChecked,
  selectFirstCheckedId,
  selectIsOneSelected,
  selectCanCollapseRow,
  selectQueryIsSearching,
  selectCanCollapse,
  selectCollapsible,
  selectChecked,
  selectShouldQueryFieldBeSorted,
  selectQuerySortBy,
  selectTableChecked,
  selectTableCollapsible,
  selectQueryPage,
  selectQueryPerPage,
  selectIsChecked,
  selectSelectedQueryArgs,
  selectQuerySortField,
  selectQuerySortOrder,
  selectDetailsItem,
} = tableSlice.selectors
export default tableSlice.reducer
export { DEFAULT_QUERY_DATA, DEFAULT_TABLE_DATA, selectRtkData }

export interface TableData {
  checked?: { [index: string]: boolean }
  lastChecked?: string
  collapsible?: { [index: string]: boolean }
  canCollapse?: { [index: string]: boolean }
  numChecked?: number
  checkedIds?: string[]
  rtkArgs?: any
  prevRtkArgs?: any
  queryArgs?: QueryArgs
  namedQueryArgs?: { [id: string]: QueryArgs }
  selectedQueryArgs?: string
  detailsItem?: string
}

export interface TableState {
  data: {
    [index: string]: TableData
  }
}

export interface QueryArgs {
  page: number
  perPage: number
  searchValue: string
  sortField: string
  sortOrder: Ordering
}
