import { Box, SxProps } from '@mui/material'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useOrgSubscription } from '../../hooks/subscription'
import { handleUploadFiles } from '../../store/slices/partUploadsSlice'

export const DragAndDropUpload = ({
  children,
  sx,
}: {
  children: React.ReactNode
  sx?: SxProps
}) => {
  const { t } = useTranslation('inbox')
  const DragAlert = 'dragAlertId'
  const [onDragOver, setOnDragOver] = useState<boolean>(false)
  const { isUnsubscribed } = useOrgSubscription({})

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    if (isUnsubscribed) return

    /**
     * Accepts files from drag and drop
     */
    e.preventDefault()
    toast.remove(DragAlert)
    setOnDragOver(false)
    const files = Array.from(e.dataTransfer.files)
    const modifiedFiles = files.map((file) => {
      // Remove any text after '.stl' in the file name:
      // (Drag and drop functionality adds random digits to files
      // in some browsers to prevent file conflicts)
      const originalName = file.name
      const indexOfExtension = originalName.toLowerCase().lastIndexOf('.stl')
      const fileNameWithoutExtension =
        indexOfExtension !== -1
          ? originalName.slice(0, indexOfExtension + 4)
          : originalName
      // Create a new File object with the modified name
      return new File([file], fileNameWithoutExtension, { type: file.type })
    })

    if (files !== null) {
      handleUploadFiles(modifiedFiles)
    }
    // Reset input to allow user to re-upload the same files again
    /* tslint:disable 
    // @ts-ignore */
    e.target.value = null
  }

  const handleOnDragFileOver = (e: React.SyntheticEvent) => {
    if (isUnsubscribed) return

    e.preventDefault()
    setOnDragOver(true)
    toast(t('label.dropFile'), {
      duration: Number.POSITIVE_INFINITY,
      id: DragAlert,
    })
  }
  const handleOnDragFileExit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setOnDragOver(false)
    toast.remove(DragAlert)
  }

  return (
    <Box
      onDragOver={(e) => handleOnDragFileOver(e)}
      onDragLeave={(e) => handleOnDragFileExit(e)}
      onDrop={(e) => onDrop(e)}
      sx={{
        width: '100%',
        height: '100%',
        ...(onDragOver && {
          border: '2px dashed black',
          borderRadius: '1rem',
          boxSizing: 'border-box',
          marginTop: '4px',
          '& > *': {
            pointerEvents: 'none',
          },
        }),
        ...sx,
      }}
      component="div"
    >
      {children}
    </Box>
  )
}
