import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { HiddenInput } from '../'
import { uploadConstants } from '../../constants/uploadConstants'
import { useOrgSubscription } from '../../hooks/subscription'
import { Button } from '../Button/Button'
import { UploadSvg } from '../Icon/Icon'

interface PartUploadButtonProps {
  marginTop?: string
  iconOnly?: boolean
  secondary?: boolean
  buttonFunction: (event: React.ChangeEvent) => void
}

export const PartUploadButton: FC<PartUploadButtonProps> = ({
  buttonFunction,
  marginTop = '0',
  iconOnly = false,
  secondary = false,
}) => {
  const { t } = useTranslation('inbox')
  const { isUnsubscribed } = useOrgSubscription({})

  return (
    <Button
      component="label"
      startIcon={!iconOnly && <UploadSvg color="inherit" />}
      color={secondary ? 'secondary' : 'primary'}
      disabled={isUnsubscribed}
      sx={{
        mt: marginTop ? marginTop : '0',
      }}
    >
      <HiddenInput
        multiple={true}
        type="file"
        accept={uploadConstants.PARTS_ACCEPTED_FILE_TYPES}
        onChange={buttonFunction}
      />
      {iconOnly ? <UploadSvg color="inherit" /> : t('button.uploadPart')}
    </Button>
  )
}
