import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface OrganisationSlice {
  orgId?: number
  shownTrialWarning: boolean
}

const initialState: OrganisationSlice = {
  orgId: undefined,
  shownTrialWarning: false,
} satisfies OrganisationSlice
const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    setOrgId(state, action: PayloadAction<number>) {
      state.orgId = action.payload
    },
    setShownTrialWarning(state, action: PayloadAction<boolean>) {
      state.shownTrialWarning = action.payload
    },
  },
  selectors: {
    selectOrgId(state) {
      return state.orgId
    },
    selectShownTrialWarning(state) {
      return state.shownTrialWarning
    },
  },
})

export const { setOrgId, setShownTrialWarning } = organisationSlice.actions
export const { selectOrgId, selectShownTrialWarning } =
  organisationSlice.selectors
export default organisationSlice.reducer
