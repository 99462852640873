import {
  CircularProgress,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { Box, useTheme } from '@mui/material'
import { BoxProps } from '@mui/system/Box'
import { Bounds, Grid, OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { FC, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import * as THREE from 'three'
import { DeviceBuildExtentsRead } from '../../store/clientApi'
import { RootState } from '../../store/store'

import {
  BuildPlatform,
  BuildPlatformTypes,
} from '../BuildPlatform/BuildPlatform'
import { CloseSvg, MaximiseSvg } from '../Icon/Icon'

interface Props {
  interactive?: boolean
  buildPlatform?: BuildPlatformTypes
  buildExtents?: DeviceBuildExtentsRead
  modelName?: string
  maximisedFooter?: ReactNode
  children: ReactNode
}

export const Viewport: FC<Props> = ({
  interactive = true,
  buildPlatform,
  buildExtents,
  modelName,
  maximisedFooter,
  children,
}) => {
  const theme = useTheme()
  const [maximised, setMaximised] = useState(false)

  const handleToggleMaximised = () => {
    setMaximised(!maximised)
  }

  return (
    <ViewportContainer
      sx={
        {
          ...(maximised && {
            backgroundColor: (theme: Theme) => theme.surface.low,
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '5',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            borderRadius: '1em',
            padding: '0',
          }),
        } as any
      }
    >
      {maximised && (
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '1em',
            boxSizing: 'border-box',
            gap: '1em',
            height: 'auto',
            width: '100%',
            borderBottom: (theme: Theme) => `1px solid ${theme.outline.main}`,
          }}
        >
          <IconButton onClick={handleToggleMaximised}>
            <CloseSvg color="inherit" />
          </IconButton>
          {modelName !== undefined && (
            <Typography variant={'h2'}>{modelName}</Typography>
          )}
        </Box>
      )}
      <Box
        component="div"
        sx={{
          position: 'relative',
          width: maximised ? '99%' : '100%',
          height: '99%',
          overflow: 'hidden',
          minHeight: '240px',
          maxHeight: maximised ? '99%' : '240px',
        }} // canvas resizing doesn't work when the size is 100%
      >
        <Canvas
          shadows
          frameloop="always"
          camera={{ fov: 20, near: 0.1, far: 5000, position: [15, 8, 15] }}
          onCreated={(state) => {
            state.scene.add(
              new THREE.AmbientLight(
                new THREE.Color(theme.model.light),
                Math.PI,
              ),
            )
            const dirLight1 = new THREE.DirectionalLight(
              new THREE.Color(theme.model.light),
              5,
            )
            dirLight1.position.set(3, 5, 5)
            state.scene.add(dirLight1)
            const dirLight2 = new THREE.DirectionalLight(
              new THREE.Color(theme.model.light),
              1,
            )
            dirLight2.position.set(-8, -10, -8)
            state.scene.add(dirLight2)
          }}
        >
          <Bounds fit margin={1.2} clip observe maxDuration={0.2}>
            {children}
          </Bounds>
          {buildPlatform !== undefined && (
            <BuildPlatform type={buildPlatform} />
          )}
          {buildExtents !== undefined && (
            <Grid
              position={[0, 0.01, 0.01]}
              args={[buildExtents.x, buildExtents.y]}
              fadeStrength={0}
              fadeDistance={10000}
              sectionSize={0}
              cellSize={1}
              cellColor={'#ffffff'}
              cellThickness={1}
            />
          )}

          {interactive && <OrbitControls makeDefault dampingFactor={1} />}
        </Canvas>
        {maximised && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              right: '1em',
              bottom: '1em',
            }}
          >
            <IconButton onClick={handleToggleMaximised}>
              <MaximiseSvg color="inherit" />
            </IconButton>
          </Box>
        )}
      </Box>
      {!maximised && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            right: '1em',
            bottom: '0.5em',
          }}
        >
          <IconButton onClick={handleToggleMaximised}>
            <MaximiseSvg color="inherit" />
          </IconButton>
        </Box>
      )}
      {maximised && maximisedFooter !== undefined && (
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '1em',
            boxSizing: 'border-box',
            gap: '1em',
            height: 'auto',
            width: '100%',
            borderTop: (theme: Theme) => `1px solid ${theme.outline.main}`,
          }}
        >
          {maximisedFooter}
        </Box>
      )}
    </ViewportContainer>
  )
}

export const ViewportContainer = (props: BoxProps) => {
  const sx: SxProps<Theme> = {
    display: 'flex',
    width: '100%',
    flexBasis: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '240px',
    position: 'relative',
    ...(props.sx && props.sx),
  }
  return (
    <Box {...props} sx={sx} component="div">
      {props.children}
    </Box>
  )
}

export const ViewportLoading = () => {
  const { modelDownloadProgress } = useSelector(
    (state: RootState) => state.viewportModel,
  )

  return (
    <ViewportContainer>
      {modelDownloadProgress ? (
        <CircularProgress
          variant="determinate"
          value={modelDownloadProgress}
          size={18}
        />
      ) : (
        <ViewportContainer>
          <CircularProgress size={18} />
        </ViewportContainer>
      )}
    </ViewportContainer>
  )
}
