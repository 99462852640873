import { useTranslation } from 'react-i18next'
import { DashboardLink } from './components/Dashboard/Dashboard'
import {
  DevicesSvg,
  InboxSvg,
  ManageSvg,
  PartsSvg,
  PrintJobsSvg,
  TimelineSvg,
} from './components/Icon/Icon'
import { PartStatus } from './constants/partStatus'
import { JobStatus } from './store/clientApi'
import { selectStatusBadge } from './store/slices/wsEventSlice'

const LoadLinks = () => {
  const { t } = useTranslation()
  const PrimaryLinks: DashboardLink[] = [
    {
      name: t('common:links.inbox'),
      link: '/inbox',
      icon: <InboxSvg color="inherit" />,
      badgeContent: (state) =>
        selectStatusBadge(state, 'parts', PartStatus.toConfirm) +
        selectStatusBadge(state, 'parts', PartStatus.toAccept),
      requiresSubscription: true,
    },
    {
      name: t('common:links.parts'),
      link: '/parts',
      icon: <PartsSvg color="inherit" />,
    },
    {
      name: t('common:links.printJobs'),
      link: '/jobs',
      icon: <PrintJobsSvg color="inherit" />,
      badgeContent: (state) =>
        selectStatusBadge(state, 'jobs', JobStatus.TO_ACCEPT),
    },
    {
      name: t('common:links.devices'),
      link: '/devices',
      icon: <DevicesSvg color="inherit" />,
    },
    {
      name: t('common:links.timeline'),
      link: '/timeline',
      icon: <TimelineSvg color="inherit" />,
    },
  ]

  const FooterLinks: DashboardLink[] = [
    {
      name: t('common:links.manage'),
      link: '/manage',
      icon: <ManageSvg color="inherit" />,
    },
  ]

  return {
    PrimaryLinks,
    FooterLinks,
  }
}

export { LoadLinks }
