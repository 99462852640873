import {
  Box,
  CircularProgress,
  LinearProgress,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { t } from 'i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  EmptyState,
  InboxInspector,
  PartBodyHeader,
  PartDataLoading,
  PartUploadButton,
} from '../../components'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { DragAndDropUpload } from '../../components/DragAndDropUpload/DragAndDropUpload'
import {
  CancelledSvg,
  CompleteSvg,
  ToAcceptSvg,
  ToReviewSvg,
} from '../../components/Icon/Icon'
import { INBOX_INSPECTOR_STATE } from '../../components/InboxInspector/InboxInspector'
import { PartButtonGroupType } from '../../components/PartActionBar/buttons'
import {
  INBOX_TABLE_STATE_NAME,
  PartsTable,
} from '../../components/PartsTable/PartsTable'
import { TabsTablePageHeader } from '../../components/Tabs/Tabs'
import { UnderConstruction } from '../../components/UnderConstruction/UnderConstruction'
import { PartStatus } from '../../constants/partStatus'
import { useGetPartsApiV1PartsGetQuery } from '../../store/baseClientApi'
import { clientApi } from '../../store/clientApi'
import { handleUploadFiles } from '../../store/slices/partUploadsSlice'
import {
  selectDrawerOpen,
  setDrawerOpen,
} from '../../store/slices/rightDrawerSlice'
import { getTab } from '../../store/slices/tabSlice'
import {
  invalidate,
  selectNumChecked,
  selectQueryArgs,
  selectQueryIsSearching,
  selectRtkData,
  setRtkArgs,
} from '../../store/slices/tableSlice'
import {
  clearStatusEvent,
  selectStatusBadge,
} from '../../store/slices/wsEventSlice'
import { RootState, store } from '../../store/store'

const tabPath = 'inbox'
const defaultTab = 'Confirm'

interface InboxProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

const InboxData = () => {
  const theme = useTheme()
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))
  const newNumSelected = useSelector((state: RootState) =>
    selectNumChecked(state, INBOX_TABLE_STATE_NAME),
  )
  const [numSelected, setNumSelected] = useState(0)
  const tab =
    useSelector((state: RootState) => getTab(state, tabPath)) ?? defaultTab

  useEffect(() => {
    if (newNumSelected > numSelected && newNumSelected === 1 && breakpointLg) {
      store.dispatch(
        setDrawerOpen({ name: INBOX_INSPECTOR_STATE, value: true }),
      )
    }
    if (numSelected !== newNumSelected) {
      setNumSelected(newNumSelected)
    }
  }, [newNumSelected, numSelected, breakpointLg])

  const queryArgs = useSelector((state: RootState) =>
    selectQueryArgs(state, INBOX_TABLE_STATE_NAME),
  )

  const filterQueryParts = (tab: string, searchValue: string) => {
    /** Filter parts based on current tab and user query */
    const statusToFilter = []
    if (tab === 'Confirm') {
      statusToFilter.push(PartStatus['uploaded'])
      statusToFilter.push(PartStatus['preProcessing'])
      statusToFilter.push(PartStatus['toConfirm'])
    } else if (tab === 'Review') {
      statusToFilter.push(PartStatus['toAccept'])
    } else if (tab === 'Accepted') {
      statusToFilter.push(PartStatus['processing'])
      statusToFilter.push(PartStatus['awaitingPlacement'])
      statusToFilter.push(PartStatus['placing'])
      statusToFilter.push(PartStatus['placed'])
      statusToFilter.push(PartStatus['printing'])
      statusToFilter.push(PartStatus['printed'])
      statusToFilter.push(PartStatus['failed'])
      statusToFilter.push(PartStatus['accepted'])
    } else if (tab === 'Rejected') {
      statusToFilter.push(PartStatus['rejected'])
    }
    // Format as: "status: X OR status: Y OR status: Z ..."
    const statusQuery = 'status:' + statusToFilter.join(` OR status:`)

    // Append user query (name search) if present
    if (searchValue !== '') {
      return `((name:*${searchValue}*) AND (${statusQuery}))`
    }
    return statusQuery
  }

  const rtkArgs = useMemo(
    () => ({
      page: queryArgs.page === 0 ? 0 : queryArgs.page - 1, // pages are zero indexed
      perPage: queryArgs.perPage,
      query: filterQueryParts(tab, queryArgs.searchValue),
      sorting:
        queryArgs.sortField !== ''
          ? `${queryArgs.sortField}:${queryArgs.sortOrder === 'asc' ? '1' : '-1'}`
          : undefined,
    }),
    [queryArgs, tab],
  )

  useEffect(() => {
    store.dispatch(setRtkArgs({ name: INBOX_TABLE_STATE_NAME, value: rtkArgs }))
  }, [rtkArgs])

  useGetPartsApiV1PartsGetQuery(rtkArgs, {})
  return <>{null}</>
}

const handleOnUploadPart = async (event: React.ChangeEvent): Promise<void> => {
  /**
   * Accepts files from the upload buttons
   */
  const target = event.target as HTMLInputElement
  const files = target.files
  if (files !== null) {
    handleUploadFiles(files)
  }
  // Reset input to allow user to re-upload the same files again
  /* tslint:disable 
  // @ts-ignore */
  event.target.value = null
}

const InboxContentInner = () => {
  const tab = useSelector((state: RootState) => getTab(state, tabPath))
  const hasContent = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      INBOX_TABLE_STATE_NAME,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )
    const isSearching = selectQueryIsSearching(state, INBOX_TABLE_STATE_NAME)
    const totalCount = query?.data?.total_count ?? 0
    const count = query?.data?.content?.length ?? 0
    return (
      count > 0 ||
      totalCount > 0 ||
      isSearching ||
      (query?.isLoading && query?.data === undefined)
    )
  })

  const getActionBarButtons = () => {
    switch (tab) {
      case 'Confirm':
      case 'Review':
      case 'Accepted':
      case 'Rejected':
        return tab.toLowerCase() as PartButtonGroupType
      // Parts Page Tabs
      default:
      case 'AllParts':
      case 'PrintReady':
      case 'Sent':
        return 'parts'
    }
  }

  const getEmptyState = () => {
    switch (tab) {
      case 'Confirm':
        return (
          <EmptyState
            image={'/images/no-parts.svg'}
            title={t('inbox:emptyInboxMessages.confirm.title')}
            message={t('inbox:emptyInboxMessages.confirm.message')}
            button={
              <PartUploadButton
                buttonFunction={handleOnUploadPart}
                marginTop={'1.5em'}
              />
            }
          />
        )
      case 'Review':
        return (
          <EmptyState
            image={'/images/noPartsToReview.svg'}
            title={t('inbox:emptyInboxMessages.review.title')}
            message={t('inbox:emptyInboxMessages.review.message')}
          />
        )
      case 'Accepted':
        return (
          <EmptyState
            image={'/images/noPartsToReview.svg'}
            title={t('inbox:emptyInboxMessages.accepted.title')}
            message={t('inbox:emptyInboxMessages.accepted.message')}
          />
        )
      case 'Rejected':
        return (
          <EmptyState
            image={'/images/noPartsRejected.svg'}
            title={t('inbox:emptyInboxMessages.rejected.title')}
            message={t('inbox:emptyInboxMessages.rejected.message')}
          />
        )
      default:
        return <EmptyState />
    }
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      {/* Action bar */}
      <PartBodyHeader
        table={INBOX_TABLE_STATE_NAME}
        actionBarType={getActionBarButtons()}
        enableSubscriptionBanner
      />
      {/* Main Table */}
      <PartsTable table={INBOX_TABLE_STATE_NAME} />
      <PartDataLoading table={INBOX_TABLE_STATE_NAME} />
      {!hasContent && getEmptyState()}
    </Box>
  )
}

const InboxContent = () => {
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      INBOX_TABLE_STATE_NAME,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )

    return query?.isLoading && query?.isUninitialized
  })

  return isLoading ? (
    <>
      <LinearProgress />
    </>
  ) : (
    <InboxContentInner />
  )
}

const Header = () => {
  const { t } = useTranslation('inbox')
  const invalidateTable = () =>
    store.dispatch(invalidate(INBOX_TABLE_STATE_NAME))
  const theme = useTheme()
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <TabsTablePageHeader
      tabPath={tabPath}
      table={INBOX_TABLE_STATE_NAME}
      title={t('title.inbox')}
      defaultTabValue={defaultTab}
      onEnterTab={() => invalidateTable()}
      buttons={
        <PartUploadButton
          iconOnly={!breakpointLg}
          secondary
          buttonFunction={handleOnUploadPart}
        />
      }
      tabs={[
        {
          label: t('label.confirm'),
          value: 'Confirm',
          icon: <ToReviewSvg color="inherit" />,
          badgeContent: (state: RootState) =>
            selectStatusBadge(state, 'parts', PartStatus.toConfirm),
          whileSelected: () => {
            store.dispatch(
              clearStatusEvent({
                type: 'parts',
                status: PartStatus.toConfirm,
              }),
            )
          },
        },
        {
          label: t('label.review'),
          value: 'Review',
          icon: <ToAcceptSvg color="inherit" />,
          badgeContent: (state: RootState) =>
            selectStatusBadge(state, 'parts', PartStatus.toAccept),
          whileSelected: () => {
            store.dispatch(
              clearStatusEvent({
                type: 'parts',
                status: PartStatus.toAccept,
              }),
            )
          },
        },
        {
          label: t('label.accepted'),
          value: 'Accepted',
          icon: <CompleteSvg color="inherit" />,
        },
        {
          label: t('label.rejected'),
          value: 'Rejected',
          icon: <CancelledSvg color="inherit" />,
        },
      ]}
    />
  )
}

const Inspector = () => {
  const tab =
    useSelector((state: RootState) => getTab(state, tabPath)) ?? defaultTab
  return (
    <InboxInspector
      table={INBOX_TABLE_STATE_NAME}
      drawer={INBOX_INSPECTOR_STATE}
      currentTab={tab}
    />
  )
}

const Inbox: FC<InboxProps> = (props) => {
  const theme = useTheme()
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))
  const { releaseInboxUi } = useFlags() // true: released, false: not released, undefined: loading
  const [isReleaseFlagTimedout, setIsReleaseFlagTimedout] =
    useState<boolean>(false)

  // States
  const inspectorOpen = useSelector((state: RootState) =>
    selectDrawerOpen(state, INBOX_INSPECTOR_STATE),
  )

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if releaseInboxUi is undefined
    if (!releaseInboxUi) {
      timeoutId = setTimeout(() => {
        setIsReleaseFlagTimedout(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if releaseInboxUi becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [releaseInboxUi])

  return (
    <>
      <InboxData />
      <Dashboard
        primaryLinks={props.primaryLinks}
        footerLinks={props.footerLinks}
      >
        {' '}
        {releaseInboxUi === true ? (
          <>
            <Box
              component="div"
              sx={{
                height: '100%',
                display: 'flex',
                gap: '1em',
                position: 'relative',
              }}
            >
              <DragAndDropUpload
                sx={{
                  display: !breakpointLg && inspectorOpen ? 'none' : 'flex',
                }}
              >
                <Box
                  component="div"
                  id="part-drop-container"
                  sx={{
                    display: !breakpointLg && inspectorOpen ? 'none' : 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    boxSizing: 'border-box',
                    border: 'none',
                    flexDirection: 'column',
                    height: 'min-content',
                    width: '100%',
                    padding: '1em',
                    borderRadius: '1em',
                    overflow: 'auto',
                    flexBasis: 'auto',
                    position: 'relative',
                    maxWidth: {
                      sm: 'calc(100vw - 8px)',
                      md: 'calc(100vw - 80px - 8px)',
                      lg: 'calc(100vw - 100px - 8px)',
                    },
                    maxHeight: '100%',
                    minHeight: '100%',
                    bgcolor: (theme: Theme) => theme.surface.low,
                  }}
                >
                  <Header />
                  <InboxContent />
                </Box>
              </DragAndDropUpload>
              <Inspector />
            </Box>
          </>
        ) : releaseInboxUi === undefined && !isReleaseFlagTimedout ? (
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <UnderConstruction />
        )}
      </Dashboard>
    </>
  )
}

export { Inbox }
